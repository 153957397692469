export default {
    en: {
        title: 'Material',
        sock:  {
            intro: 'Your socks are made with organic cotton.',
            info: 'See how they are made',
            options: [
                'organic cotton'
            ]
        },
        scarf: {
            intro: 'Your scarf is made with organic merino wool.',
            info: 'See how it’s made',
            options: [
                'organic merino wool'
            ]
        },
        continue: 'Ok, continue'
    },
    nl: {
        title: 'Materiaal',
        sock:  {
            intro: 'Je sokken worden gebreid met biologisch katoen en elastische nylon lycra.',
            info: 'Hier zie je precies waar jouw sokken van worden gemaakt.',
            options: [
                'biologisch katoen'
            ]
        },
        scarf: {
            intro: 'Je sjaal wordt gebreid met biologische merinowol.',
            info: 'Hier zie je precies hoe dat wordt gemaakt.',
            options: [
                'biologische merinowol'
            ]
        },
        continue: 'Ok, doorgaan'
    }
};
